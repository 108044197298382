// src/services/listGameService.js
import axios from "axios";
import authService from "./authService";

const API_URL = process.env.REACT_APP_API_URL + "/api/lists";

const fetchGameLists = async () => {
  let token = await authService.getAuthToken();

  try {
    const response = await axios.get(API_URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      // Server responded with an HTTP status code
      console.error("Server Error:", error.response.status, error.response.data);
      if (error.response.status === 401 || error.response.status == 403 || error.response.status === 503) {
        try {
          token = await authService.refreshAuthToken();
          const retryResponse = await axios.get(API_URL, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          return retryResponse.data;
        } catch (retryError) {
          console.error("Retry after token refresh failed:", retryError);
          throw retryError;
        }
      }
    } else if (error.request) {
      // No response received from the server
      console.error("No response received:", error.request);
    } else {
      // Something else went wrong
      console.error("Error:", error.message);
    }
    throw error;
  }
}

export default {
  fetchGameLists,
};

