import React, { createContext, useContext, useState } from 'react';

// Create separate contexts for game and answer
const GameContext = createContext({
  selectedGame: null,
  setSelectedGame: () => {},
});

const AnswerContext = createContext({
  selectedAnswer: null,
  setSelectedAnswer: () => {},
});

// Custom hooks for accessing the contexts
export const useGame = () => useContext(GameContext);
export const useAnswer = () => useContext(AnswerContext);

// Provider component
export const GameProvider = ({ children }) => {
  const [selectedGame, setSelectedGame] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  return (
    <GameContext.Provider value={{ selectedGame, setSelectedGame }}>
      <AnswerContext.Provider value={{ selectedAnswer, setSelectedAnswer }}>
        {children}
      </AnswerContext.Provider>
    </GameContext.Provider>
  );
};
