import React from 'react';

const GameAnswer = ({ gameListItem, onSelect }) => {
  return( 
    <div className='my-3 scroll-m-2 rounded-lg p-3 bg-gray-50 shadow-sm' onClick={() => onSelect(gameListItem)}>
      <p className='text-gray-700'>{gameListItem.answer}</p>
    </div>
  );
}

export default GameAnswer;
