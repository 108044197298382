import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGame } from "../context/GameContext";
import { useAnswer } from "../context/GameContext";
import GameAnswer from "../components/GameAnswer";

const GameListPage = () => {
  const navigate = useNavigate();
  const { selectedGame } = useGame();
  const { setSelectedAnswer } = useAnswer();

  const onSelectItem = (item) => {
    setSelectedAnswer(item);
    console.log("selected answer:");
    console.log(item);
    navigate("/phoneVerification");
  };

  return (
    <div className="oswald-400">
      <h1 className="text-3xl pb-6 oswald-600">{selectedGame.name}</h1>
      <h1 className="text-xl pb-6 oswald-300">{selectedGame.challenge}</h1>
      <div className="pb-10">
        {selectedGame.listItems.map((item) => (
          <GameAnswer
            key={item.id}
            gameListItem={item}
            onSelect={onSelectItem}
          />
        ))}
      </div>
    </div>
  );
};

export default GameListPage;
