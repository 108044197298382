import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import gameService from '../services/listGameService'
import GameItem from '../components/GameItem'
import Spinner from '../components/spinner' 
import { useGame } from '../context/GameContext'

const GameListPage = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false);
  const [games, setGames] = useState([]);
  const {setSelectedGame} = useGame()

  useEffect(() => {
    setIsLoading(true)
    const fetchGames = async () => {
      try {
        const gameList = await gameService.fetchGameLists()
        setGames(gameList)
      } catch(e){
        console.log(e)
      } finally{
        setIsLoading(false)
      }
    }
      fetchGames()
  }, []) 

  const onSelectGame = (game) => {
    console.log('selected game:')
    console.log(game)
    setSelectedGame(game)
    navigate('/game')
  }

    return (
    <div className="oswald-400">
      <h1 className="text-3xl pb-6 oswald-600">Available Games</h1>
      <h1 className="text-xl pb-6 oswald-300">Please select a game to participate</h1>
      {isLoading? 
        <Spinner/> : 
        <div className="pb-10">
          {games.map((game) => (
            <GameItem key={game.id} game={game} onSelect={onSelectGame} />
          ))}
        </div>
      }
    </div>
    )

}

export default GameListPage;
