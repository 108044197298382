import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import VotePage from "./pages/VotePage";
import GameListPage from "./pages/GameListPage";
import GameDetails from "./pages/GamePage";
import { GameProvider } from "./context/GameContext";
import { PhoneNumberProvider } from "./context/PhoneNumberContext";
import PaymentPage from "./pages/PaymentPage";
import PhoneVerificationPage from "./pages/PhoneVerificationPage";
import "./App.css";

const App = () => (
  <Router>
    <GameProvider>
      <PhoneNumberProvider>
        <Routes>
          <Route path="/" element={<GameListPage/>} />
          <Route path="/game" element={<GameDetails/>} />
          <Route path="/payment" element={<PaymentPage />} />
          <Route
            path="/phoneVerification"
            element={<PhoneVerificationPage />}
          />
          <Route path="/success" element={<h1>Payment Successful!</h1>} />
        </Routes>
      </PhoneNumberProvider>
    </GameProvider>
  </Router>
);

export default App;
